/**
 * @summary addNodeModal.js
 * @file A modal which allows users to add nodes into a neighborhood
 * @returns {JSX}
 * @usedBy NeighbordhoodNodesSettings.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import store from '../../../store/store';
import { addNodesToNeighborhood } from 'store/neighborhoods/NeighborhoodActions';
import KendoGridBase from 'shared/ui/kendoGridBase/KendoGridBase';

const AddNodeModal = ({ show, setShow }) => {
  const defaultHeaders = [
    {
      field: 'selected',
      show: true,
      filterable: false
    },
    {
      field: 'name',
      title: 'Name',
      show: true,
      filterable: false,
      filter: 'text',
      width: '120vw'
    },
    {
      field: 'nodeKey',
      title: 'Key',
      show: true,
      filterable: false,
      filter: 'text',
      width: '120vw'
    },
    {
      field: 'description',
      title: 'Description',
      show: true,
      filterable: false,
      filter: 'text',
      width: '500vw'
    }
  ];

  const nodes = useSelector((state) => {
    const nodes = { ...state.nodeReducer };
    delete nodes?.selectedData;
    return Object.values(nodes).filter(
      (node) =>
        !state?.neighborhoodReducer?.selectedData[0]?.nodeIds?.includes(
          node?.id
        ) && node?.branchId === state?.authReducer?.userObj?.selectedBranch[0]?.id
    )
  }, shallowEqual)

  const [gridState, setGridState] = useState({
    nodecollections: {
      data: nodes?.map((node) => ({ ...node, selected: false })),
      total: 0
    },
    dataState: { take: 20, skip: 1 },
    gridDynamicColumns: defaultHeaders
  });

  const [selectedNodes, setSelectedNodes] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 25 });

  // HANDLES SAVE CLICK
  const onSave = () => {
    store.dispatch(addNodesToNeighborhood(selectedNodes));
  };

  // HANDLES SELECTION CHANGE
  const selectionChange = (e) => {
    const data = gridState.nodecollections.data.map((node) => {
      if (node === e.dataItem) {
        node.selected = !e.dataItem.selected;
        if (node.selected === true) {
          setSelectedNodes([...selectedNodes, node.id]);
        } else {
          const filteredSelectedState = selectedNodes.filter(
            (nodeId) => nodeId !== e.dataItem.id
          );
          setSelectedNodes(filteredSelectedState);
        }
      }
      return node;
    });

    const gridObjs = { ...gridState.nodecollections };
    gridObjs.data = data;
    setGridState({ ...gridState, nodecollections: gridObjs });
  };

  // Skip Grid Pages
  const handlePageChange = (e) => {
    e.page.skip = isNaN(e.page.skip) ? 1 : e.page.skip;
    setPage(e.page);
  };

  // Update Grid Data
  const updateGridData = (data) => {
    const nodeIdsForCheckOut = data.filter((node) => node.selected);
    setSelectedNodes(nodeIdsForCheckOut);
    setGridState({ ...gridState, nodes: [...data] });
  };

  function sortByNodeKey(arr) {
    return arr.sort((a, b) => {
        const aParts = a.nodeKey.split(/[-\s]/);
        const bParts = b.nodeKey.split(/[-\s]/);
        for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
            const aPart = aParts[i] || '';
            const bPart = bParts[i] || '';
            const aNum = parseFloat(aPart);
            const bNum = parseFloat(bPart);

            if (!isNaN(aNum) && !isNaN(bNum)) {
                if (aNum !== bNum) return aNum - bNum;
            } else {
                const comparison = aPart.localeCompare(bPart, undefined, { numeric: true, sensitivity: 'base' });
                if (comparison !== 0) return comparison;
            }
        }
        return 0;
    });
}

  const sortedData = gridState?.nodecollections?.data ? sortByNodeKey(gridState.nodecollections.data) : [];

  return (
    <>
      <Modal show={show} centered size="lg" backdrop="static">
        <Modal.Header>
          <Modal.Title>
          <div className='white-modal-header'>
            Add Nodes to Neighborhood
          </div>
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='modal-body-spacing'>
            <div style={{ width: '100%', position: 'relative' }}>
              <KendoGridBase
                data={sortedData || []}
                gridColumns={gridState.gridDynamicColumns}
                onSelectionChange={selectionChange}
                onRowSingleClick={selectionChange}
                onPageChange={handlePageChange}
                rowHeight={40}
                skip={page.skip}
                take={page.take}
                total={
                  gridState.nodecollections ? gridState.nodecollections.total : 0
                }
                updateGridData={updateGridData}
                onSelectAllChange={() => {}}
                selectable="selected"
                pageable
                resizable={true}
                noMenu={true}
                sortable={true}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <div className='container'>
          <div className='row'>
            <div className='bootstrap-modal-footer-button-alignment'>
              <button
                className="btn btn-danger btn-sm bootstrap-footer-button-stylization"
                onClick={() => setShow(false)}
              >
                Close
              </button>
              <button
                className="btn btn-primary btn-sm bootstrap-footer-button-stylization"
                onClick={() => {
                  onSave();
                  setShow(false);
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

AddNodeModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func
};

export default AddNodeModal;
